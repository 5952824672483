<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">Lista de Força de Vendas</p>
      </div>
      <ForcaDeVendaTabela
        :headers="headers"
        :forcaDeVenda="forcaDeVenda"
        :total="total"
        :page="formData.page"
        :pageText="pageText"
        :loading="loading"
        @handleFilter="filter"
      >
        <template v-slot:name>
          <v-text-field
            v-model="formData.name"
            placeholder="Busque pelo nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="getSearchSaleForce(formData);formData.page = 1"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:code>
          <v-text-field
            v-model="formData.code"
            placeholder="Busque pelo código"
            solo
            flat
            dense
            :hide-details="true"
            @input="getSearchSaleForce(formData);formData.page = 1"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ForcaDeVendaTabela>
    </div>
  </div>
</template>

<script>
import ForcaDeVendaTabela from "@/components/forca-de-venda/ForcaDeVendaTabela.vue";
import { searchSaleForce } from "@/services/sale-force.js";
export default {
  name: "ForcaDeVendas",
  components: { ForcaDeVendaTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Força de Venda",
        disabled: true,
        to: "",
      },
    ],
    headers: [
      { text: "Nome", value: "name", width: "45%" },
      { text: "Código", value: "code", width: "45%" },
      { text: "Ações", value: "id", sortable: false },
    ],
    formData: {
      sort: null,
      perPage: 20,
      page: 1,
      name: null,
      code: null,
    },
    forcaDeVenda: [],
    total: null,
    pageText: null,
    loading: false,
  }),
  created() {
    if (this.$store.state.searchSaleForceHistory.length) {
      this.formData = { ...this.$store.state.searchSaleForceHistory[0] };
    }
    this.getSearchSaleForce(this.formData);
  },
  methods: {
    async getSearchSaleForce(payload) {
      this.forcaDeVenda = [];
      this.$store.dispatch("setSearchSaleForceHistory", payload);
      try {
        this.loading = true;
        const resp = await searchSaleForce(payload);
        this.forcaDeVenda = resp.data;
        this.total = resp.meta.total;
        this.pageText = `${resp.meta.from}-${resp.meta.to} de ${resp.meta.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    filter(event) {
      this.$store.dispatch("setSearchSaleForceHistory", this.formData);
      if (event.orderBy) {
        this.formData.sort = event.orderBy + event.orderSorted;
      } else {
        this.formData.sort = null;
      }
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.getSearchSaleForce(this.formData);
    },
    clear() {
      this.$store.dispatch("setSearchSaleForceHistory", {});
      this.formData.name = null;
      this.formData.code = null;
      this.formData.page = 1;
      this.getSearchSaleForce(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.getSearchSaleForce(this.formData);
    },
  },
};
</script>
