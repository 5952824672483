<template>
  <v-data-table
    :headers="headers"
    :items="forcaDeVenda"
    :items-per-page="20"
    :page="page"
    class="elevation-1 tabela-forca-de-venda"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Itens por página:',
      'items-per-page-options': [20, 50, 100],
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-btn
        v-if="checkPermission('saleForce.editProduct')"
        small
        depressed
        color="fmq_gray"
        dark
        @click="gotTo(item.id)"
        >Detalhes</v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
import { checkPermission } from "@/utils";
export default {
  name: "ForcaDeVendaTabela",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    forcaDeVenda: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageText: {
      type: String,
      default: "-",
    },
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
  }),
  created() {
    this.formData.page = this.page;
  },
  methods: {
    checkPermission,
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? ":desc" : ":asc"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    gotTo(id) {
      this.$router.push({ name: "ForcaDeVendasExibir", params: { id: id } });
    },
  },
};
</script>
<style lang="scss">
.tabela-forca-de-venda tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-forca-de-venda tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
